import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

import TopPanel from '../../components/TopPanel';
import Form from './Form';
import useGlobalStyles from '../../theme/useGlobalStyles';
import LocationIcon from '../../components/icons/location.svg';
import MailIcon from '../../components/icons/mail.svg';

const useStyles = makeStyles((theme: any) => ({
  root: {},
  top: {
    padding: 105,
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.sm]: {
      padding: '48px 135px',
      flexDirection: 'column',
      alignItems: 'stretch',
      justifyContent: 'flex-start',
    },
    [theme.breakpoints.xs]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  },
  pageTitle: {
    textTransform: 'uppercase',
    fontWeight: 400,
    textAlign: 'left',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(3),
    lineHeight: '56px',
    [theme.breakpoints.sm]: {
      marginTop: 0,
      textAlign: 'center',
    },
    [theme.breakpoints.xs]: {
      marginBottom: theme.spacing(2),
    },
  },
  pageDescription: {
    textAlign: 'left',
    maxWidth: 450,
    [theme.breakpoints.sm]: {
      textAlign: 'center',
    },
    [theme.breakpoints.xs]: {
      marginBottom: theme.spacing(5),
    },
  },
  contacts: {
    width: 500,
    border: '1px solid #6C6C6C',
    [theme.breakpoints.sm]: {
      width: 'auto',
    },
    [theme.breakpoints.xs]: {
      margin: 0,
    },
  },
  panel: {
    padding: theme.spacing(4),
    display: 'flex',
    alignItems: 'center',
    fontSize: 20,
    lineHeight: '32px',
    textAlign: 'left',
    '&:first-child': {
      paddingRight: theme.spacing(4),
      borderBottom: '1px solid #6C6C6C',
    },
    [theme.breakpoints.sm]: {
      '&:first-child': {
        paddingRight: theme.spacing(2),
      }
    },
    [theme.breakpoints.xs]: {
      padding: theme.spacing(3),
      fontSize: 18,
      lineHeight: '26px',
    },
  },
  icon: {
    width: 72,
    height: 72,
    flex: '0 0 auto',
    background: 'no-repeat center rgba(255, 255, 255, 0.2)',
    borderRadius: 40,
    marginRight: theme.spacing(4),
    '&.address': {
      backgroundImage: `url("${LocationIcon}")`,
    },
    '&.mail': {
      backgroundImage: `url("${MailIcon}")`,
    },
    [theme.breakpoints.xs]: {
      width: 48,
      height: 48,
      marginRight: theme.spacing(2),
    },
  },
}));

export default function ContactsPage() {
  const globalClasses = useGlobalStyles();
  const classes = useStyles();

  return (
    <>
      <TopPanel hideButton>
        <div className={classes.top}>
          <div>
            <div className={clsx(globalClasses.pageTitle, classes.pageTitle)}>
              Contact Us
            </div>
            <div
              className={clsx(
                globalClasses.pageDescription,
                classes.pageDescription
              )}
            >
              Please feel free to contact us any time. <br />
              We will respond as soon as possible.
            </div>
          </div>
          <div className={classes.contacts}>
            <div className={classes.panel}>
              <div className={clsx(classes.icon, 'address')} />
              <span>
                57/63 Line Wall Road, Gibraltar, GX11 1AA
              </span>
            </div>
            <div className={classes.panel}>
              <div className={clsx(classes.icon, 'mail')} />
              <span>prime@cex.io</span>
            </div>
          </div>
        </div>
      </TopPanel>
      <Form />
    </>
  );
}
